// Exception logging
// import Sentry from './lib/sentry'

// Rails
import Rails from './rails/rails-ujs'
import '@hotwired/turbo-rails'

// App
import './controllers/fudgeball'
import Polyfills from './lib/polyfills'
import MinimalAnalytics from './lib/minimal_analytics'
import ImageLazyLoading from './lib/image_lazy_loading'

//  Start everything that needs to be started
// Sentry.start()
Rails.start()
Polyfills.start()
MinimalAnalytics.start()
ImageLazyLoading.start()
